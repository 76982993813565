import React from "react"

import Layout from "../components/Layout"
import Container from "../components/Container";
import ModuleSpacing from "../components/ModuleSpacing";
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
	<Container>
		<ModuleSpacing top>
			<h1>404: Not Found</h1>
			<p>You just hit a page that doesn&#39;t exist...</p>
		</ModuleSpacing>
	</Container>
  </Layout>
)

export default NotFoundPage
